import React from 'react'
import PropTypes from 'prop-types'

import style from './Section.module.scss'

const Section = ({children, className, ...props}) => (
  <section className={style.section}>
    <div
      className={`${style.container} ${className || ''}`}
      {...props}
    >
      {children}
    </div>
  </section>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Section
