import React from 'react'
import SVG from 'react-inlinesvg'
import Fade from 'react-reveal/Fade'

import Section from '../../../../components/section/Section'
import StyledLink from '../../../../components/styled-link/StyledLink'

import ArrowLinkIcon from '../../../../../static/assets/icons/navigation/arrow-link.svg'

import socialsConfig from '../../../../../configuration/socials.yml'

import style from './Socials.module.scss'

const Socials = () => (
  <Section className={style.socials}>
    <Fade bottom distance="25%">
      <div className={style.title}>
        <h2>Cash me online<br />how about that</h2>
      </div>
    </Fade>
    <div className={style.content}>
      <Fade bottom distance="25%">
        <div className={style.list}>
          {socialsConfig && socialsConfig.length && socialsConfig.map(item => (
            <div
              key={item.id}
              className={style.item}
            >
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledLink
                  className={style.link}
                  icon={ArrowLinkIcon}
                >
                  <SVG
                    src={`assets/icons/socials/${item.icon}`}
                    className={style.icon}
                    style={{fill: item.color}}
                  />
                  <span>{item.label}</span>
                </StyledLink>
              </a>
            </div>
          ))}
        </div>
      </Fade>
    </div>
  </Section>
)

export default Socials
